<template>
  <div>
    <!-- Table Container Card -->

    <b-card no-body class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <!-- Table Top -->

          <b-row>
            <!-- Per Page -->
            <b-col cols="12" md="7" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>Show</label>
              <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
              <label>entries </label>
            </b-col>

            <b-col cols="12" md="5">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="filter" class="d-inline-block mr-1" cle placeholder="Search..." />
                <b-button v-show="$Can('vehicle_add_btn')" variant="primary" router-link to="/vehicle/vehicle-add">
                  <span class="text-nowrap">Add Vehicle</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative"
          :items="fetchUsers"
          selectable
          select-mode="single"
          responsive
          :small="true"
          head-variant="dark"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          @row-selected="onRowSelected"
        > -->

        <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative"
          :style="totalRows < 3 ? 'height: 500px' : ''"
          :items="fetchVehicles"
          :filter="filter"
          filter-debounce="250"
          @filtered="onFiltered"
          :filter-included-fields="filterOn"
          selectable
          select-mode="single"
          responsive
          :small="true"
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          empty-text="No matching records found"
          @row-selected="onRowSelected"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <!-- Column: Id -->
          <template #cell(id)="data">
            <!--   <b-link :to="{ name: 'customer-edit', params: { id: data.item.id } }" class="font-weight-bold"> #{{ data.value }} </b-link>-->
            <strong class="text-primary">#{{ data.value }}</strong>
          </template>

          <!-- Column: Name -->
          <template #cell(mot_due)="data">
            {{ dateFormat(data.value) }}
          </template>

          <template #cell(service_due)="data">
            {{ dateFormat(data.value) }}
          </template>

          <template #cell(tax_due)="data">
            {{ dateFormat(data.value) }}
          </template>

          <!-- Column: Name -->
          <template #cell(surname)="data">
            {{ data.value }}
          </template>

          <!-- Column: Role -->
          <template #cell(role)="data">
            <b-badge pill :variant="`light-primary`" class="text-capitalize">
              {{ data.value }}
            </b-badge>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge v-if="data.value == 'A'" pill :variant="`light-success`" class="text-capitalize">
              Active
            </b-badge>

            <b-badge v-if="data.value == 'P'" pill :variant="`light-warning`" class="text-capitalize">
              Inactive
            </b-badge>

            <b-badge v-if="data.value == 'D'" pill :variant="`danger`" class="text-capitalize">
              Removed
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item :to="{ name: 'vehicle-view', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'vehicle-edit', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <a class="ml-2 mb-2 mr-2 float-right text-primary" v-if="!archive" @click="archiveOn">Show removed records</a>
        <a class="ml-2 mb-2 mr-2 float-right text-primary" v-else @click="archiveOff">Show active records</a>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import useUserList from './useUserList';
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import { avatarText } from '@core/utils/filter';
import { onUnmounted } from '@vue/composition-api';
import vehicleStoreModule from '../vehicleStoreModule';
import vSelect from 'vue-select';
import axios from 'axios';
import axiosIns from '@/libs/axios';
import router from '@/router';
import moment from 'moment';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
  },

  setup() {
    const VEHICLE_APP_STORE_MODULE_NAME = 'vehicle';

    // Register module
    if (!store.hasModule(VEHICLE_APP_STORE_MODULE_NAME)) store.registerModule(VEHICLE_APP_STORE_MODULE_NAME, vehicleStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VEHICLE_APP_STORE_MODULE_NAME)) store.unregisterModule(VEHICLE_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      fetchVehicles: [],
      dropDownUsers: [],
      archive: false,

      filterOn: ['type', 'seat_count', 'vehicle_reg', 'ownership'],
      tableColumns: [
        { key: 'type', sortable: true, class: 'text-center' },
        { key: 'seat_count', sortable: true, class: 'text-center' },
        { key: 'vehicle_reg', sortable: true, class: 'text-center' },
        { key: 'ownership', sortable: true, class: 'text-center' },
        { key: 'mot_due', sortable: true, class: 'text-center' },
        { key: 'service_due', sortable: true, class: 'text-center' },
        { key: 'tax_due', sortable: true, class: 'text-center' },
        { key: 'actions', sortable: false },
      ],
      perPage: localStorage.getItem('vehicleShow') ?? 10,
      currentPage: 1,
      totalRows: 0,
      selectedUserId: null,
      deleteUserId: null,

      perPageOptions: [10, 25, 50, 100],
      filter: null,

      loading: false,
    };
  },

  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem('vehicleShow', val);
      },
    },
  },

  methods: {
    dateFormat(val) {
      if (val != null && val != '') {
        moment.locale('en-US');
        return moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY');
      } else {
        return 'N/A';
      }
    },

    onRowSelected(item) {
      router.push({ name: 'vehicle-view', params: { id: item[0].id } });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    archiveOn() {
      this.loading = true;
      this.archive = true;
      store
        .dispatch('vehicle/fetchArchiveVehicles', [])
        .then((res) => {
          this.fetchVehicles = res.data;

          this.totalRows = res.data.length;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    archiveOff() {
      this.archive = false;
      this.getVehicles();
    },

    getVehicles() {
      this.loading = true;
      store
        .dispatch('vehicle/fetchVehicles', [])
        .then((res) => {
          this.fetchVehicles = res.data;

          this.totalRows = res.data.length;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  created() {
    this.getVehicles();
  },

  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },

    selectUserState() {
      return this.selectedUserId != null && this.selectedUserId != '' ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
